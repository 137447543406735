// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-act-js": () => import("./../../../src/pages/act.js" /* webpackChunkName: "component---src-pages-act-js" */),
  "component---src-pages-greenscreen-js": () => import("./../../../src/pages/greenscreen.js" /* webpackChunkName: "component---src-pages-greenscreen-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-countries-data-jsx": () => import("./../../../src/templates/CountriesData.jsx" /* webpackChunkName: "component---src-templates-countries-data-jsx" */),
  "component---src-templates-country-jsx": () => import("./../../../src/templates/Country.jsx" /* webpackChunkName: "component---src-templates-country-jsx" */),
  "component---src-templates-i-frame-country-jsx": () => import("./../../../src/templates/IFrameCountry.jsx" /* webpackChunkName: "component---src-templates-i-frame-country-jsx" */)
}

